export const COMMON_LABELS = {
  ALL: "All",
};

export const CONTENT_TYPE = {
  BULLET_POINT: "BULLET_POINT",
  PARAGRAPH: "PARAGRAPH",
};

export const PAGE_ID = {
  INDUSTRIES: "industries",
};

export const SUBPAGES_LIST = ["industries", "careers", "services"];
export const CHECK_SUBPAGE = /[a-zA-Z]\/[a-zA-Z]/gm;

export const MEMBER_TYPE = {
  EMPLOYEE: "Employee",
  MANAGEMENT: "Management",
};

export const SERVICE_HEIGHT_EXCEPTION = {
  data_science_serv: ["33rem", null, null, "auto"],
};
